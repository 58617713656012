import axios from 'axios'

import { customHistory } from './history'

// export const cancelTokenSource = axios.CancelToken.source()
// cancelToken: cancelTokenSource.token

export const controller = new AbortController()
const user = localStorage.getItem('user')
let token = ''
if (user) {
  try {
    const parsedUser = JSON.parse(user)
    if (parsedUser && parsedUser.token) {
      token = parsedUser.token
    }
  } catch (e) {}
}

export const api = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // Don't need with new version axios
    // accept: 'text/plain',
    // 'Content-Type': 'application/json-patch+json',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiAlt = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_ALT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // Don't need with new version axios
    // accept: 'text/plain',
    // 'Content-Type': 'application/json-patch+json',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiOpen = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_OPEN,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiOpt = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_OPT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // Don't need with new version axios
    // accept: 'text/plain',
    // 'Content-Type': 'application/json-patch+json',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiImprt = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_IMPRT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // Don't need with new version axios
    // accept: 'text/plain',
    // 'Content-Type': 'application/json-patch+json',
    Authorization: token,
  },
  signal: controller.signal,
})

api.interceptors.response.use(
  function (response) {
    if (response.status === 404) {
      api.defaults.headers['Authorization'] = ''
      localStorage.setItem(
        'user',
        JSON.stringify({ token: '', isAuthenticated: false, trustPeriod: '' })
      )
      customHistory.push('/')
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const apiIdentity = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiParcel = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_PARCEL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: token,
  },
  signal: controller.signal,
})

export const apiParcels = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL_PARCEL_PROD,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: token,
  },
  signal: controller.signal,
})
