import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import Button from '../../components/Button'

import styles from './styles.module.scss'

interface IProps {}

const AuthencityWidget: FC<IProps> = () => {
  const [baseUrl, setBaseUrl] = useState('https://qwertyabc.authencity.io')

  const handleChangeBaseUrl = () => {
    if (baseUrl === 'http://localhost:3000') {
      setBaseUrl('https://qwertyabc.authencity.io')
    } else {
      setBaseUrl('http://localhost:3000')
    }
  }

  return (
    <div className={styles.authencityWidget}>
      <Button
        styleType={'primary'}
        onClick={handleChangeBaseUrl}
        style={{
          width: 'auto',
          padding: '0 10px',
        }}
      >
        Change src
      </Button>

      <p>{baseUrl}</p>

      <iframe
        id="authencityWidget"
        width="800"
        height="500"
        src={`${baseUrl}/widget/w1`}
        title="Authencity Widget"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default memo(AuthencityWidget)
