import React, { CSSProperties, FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import styles from './styles.module.scss'

interface IProps {
  styleType: 'primary' | 'secondary',
  type?: 'submit' | 'reset' | 'button';
  style?: CSSProperties
  children: React.ReactNode
  onClick?: () => void
  // onClickWithEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: FC<IProps> = ({ styleType, style, children, onClick, type = 'button' }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (type !== 'submit') {
      event.preventDefault()
      event.stopPropagation()
    }
    onClick && onClick()
    // onClickWithEvent && onClickWithEvent(event)
  }

  return (
    <button onClick={handleOnClick}
            ref={buttonRef}
            className={cn({
              [styles.button]: true,
              [styles.primary]: styleType === 'primary',
              [styles.secondary]: styleType === 'secondary'
            })}
            type={type}
            style={style}
    >
      {children}
    </button>
  )
}

export default Button
