import { jwtDecode } from 'jwt-decode'
import { makeAutoObservable } from 'mobx'
import {
  IAccount,
  IAccountPersonalInformationName,
  IAccountSecurity,
  IJoinAlpha,
  ILoginIn,
  ILoginInTwoFactor,
  ISignUp,
} from 'models'

import {
  AccessRolesType,
  api,
  apiAlt,
  apiIdentity,
  apiImprt,
  apiOpen,
  apiOpt,
  apiParcel,
  apiParcels,
} from 'utils'

import userStore from './user'

interface JwtPayload {
  exp: number
  iat: number
  nameid: string
  nbf: number
  role: AccessRolesType[]
  sub: string
  unique_name: string
}

class AccountStore {
  account: IAccount | null = null
  accountRole: AccessRolesType[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setAccount(value: IAccount | null) {
    if (value) {
      this.account = value
      if (value.Token) {
        const decoded: JwtPayload = jwtDecode(value.Token)
        this.accountRole = decoded.role
      }
    } else {
      this.account = value
      this.accountRole = []
    }
  }

  async signUp(data: ISignUp) {
    // make date for server
    if (data.dateOfBirth) {
      let birthDate = new Date(data.dateOfBirth)
      birthDate = new Date(
        birthDate.getTime() - birthDate.getTimezoneOffset() * 60000
      )
      data = { ...data, dateOfBirth: birthDate.toISOString() as any }
    }

    const response = await api.post(`api/Account`, data)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async loginIn(data: ILoginIn, trustPeriod: number = 0) {
    const response = await api.post(`api/Account/login`, data)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    if (response.data && response.data.Token) {
      let trustData = ''
      if (trustPeriod) {
        const dateNow = new Date()
        trustData = String(dateNow.setDate(dateNow.getDate() + trustPeriod))
      }

      api.defaults.headers['Authorization'] = response.data.Token
      apiAlt.defaults.headers['Authorization'] = response.data.Token
      apiIdentity.defaults.headers['Authorization'] = response.data.Token
      apiImprt.defaults.headers['Authorization'] = response.data.Token
      apiOpen.defaults.headers['Authorization'] = response.data.Token
      apiOpt.defaults.headers['Authorization'] = response.data.Token
      apiParcel.defaults.headers['Authorization'] = response.data.Token
      apiParcels.defaults.headers['Authorization'] = response.data.Token

      userStore.setUserInfo(response.data.Token, true, trustData)

      this.setAccount(response.data)
    }

    return response.data
  }

  async loginInTwoFactor(data: ILoginInTwoFactor, trustPeriod: number = 0) {
    const response = await api.post(`api/Account/loginTwoFactor`, data)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    if (response.data && response.data.Token) {
      let trustData = ''
      if (trustPeriod) {
        const dateNow = new Date()
        trustData = String(dateNow.setDate(dateNow.getDate() + trustPeriod))
      }

      api.defaults.headers['Authorization'] = response.data.Token
      apiAlt.defaults.headers['Authorization'] = response.data.Token
      apiIdentity.defaults.headers['Authorization'] = response.data.Token
      apiImprt.defaults.headers['Authorization'] = response.data.Token
      apiOpen.defaults.headers['Authorization'] = response.data.Token
      apiOpt.defaults.headers['Authorization'] = response.data.Token
      apiParcel.defaults.headers['Authorization'] = response.data.Token
      apiParcels.defaults.headers['Authorization'] = response.data.Token

      userStore.setUserInfo(response.data.Token, true, trustData)

      this.setAccount(response.data)
    }

    return response.data
  }

  async loginOpen(data: ILoginIn) {
    const response = await apiOpen.post(`api/Account/login`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async forgotPasswordCode(email: string) {
    const response = await api.post(`api/Account/ForgotPasswordCode`, null, {
      params: {
        email,
      },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async forgotPasswordCode_beta(email: string) {
    const response = await apiAlt.post(`api/Account/ForgotPasswordCode`, null, {
      params: {
        email,
      },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async confirmForgotPasswordCode(email: string, code: string) {
    const response = await api.post(
      `api/Account/ConfirmForgotPasswordCode`,
      null,
      {
        params: {
          email,
          code,
        },
      }
    )

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async confirmForgotPasswordCode_beta(email: string, code: string) {
    const response = await apiAlt.post(
      `api/Account/ConfirmForgotPasswordCode`,
      null,
      {
        params: {
          email,
          code,
        },
      }
    )

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async createNewPassword(email: string, code: string, password: string) {
    const response = await api.post(`api/Account/CreateNewPassword`, null, {
      params: {
        email,
        code,
        password,
      },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async createNewPassword_beta(email: string, code: string, password: string) {
    const response = await apiAlt.post(`api/Account/CreateNewPassword`, null, {
      params: {
        email,
        code,
        password,
      },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async sendRequestToAlpha(data: IJoinAlpha) {
    const response = await apiAlt.post(`api/Account/sendRequestToAlpha`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async confirmEmailAlpha(email: string, code: string) {
    const response = await apiAlt.post(`api/Account/confirmEmailAlpha`, null, {
      params: {
        email,
        code,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async subscribeMailing(email: string) {
    const response = await api.post(`api/Account/subscribe`, null, {
      params: {
        email,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getAccount() {
    const response = await api.get(`api/Account`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getAccountInfo(token: any) {
    apiOpen.defaults.headers['Authorization'] = token
    const response = await apiOpen.get(`api/Account/accountInfo`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async updateAccount(data: IAccountPersonalInformationName) {
    const response = await api.patch(`api/Account`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async sendCodeForChangeEmail(newEmail: string) {
    const response = await api.post(
      `api/Account/sendCodeForChangeEmail`,
      null,
      {
        params: {
          newEmail,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async validateCodeForChangeEmail(newEmail: string, code: string) {
    const response = await api.post(
      `api/Account/validateCodeForChangeEmail`,
      null,
      {
        params: {
          newEmail,
          code,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async changePassword(data: IAccountSecurity) {
    const response = await api.post(`api/Account/ChangePassword`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async verifyAuthenticatorCode(username: string, code: string) {
    const response = await api.get(`api/TwoFactor/verifyAuthenticatorCode`, {
      params: {
        username,
        code,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getBackupCode() {
    const response = await api.get(`api/TwoFactor/getBackupCode`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async enterBackupCode(backupCode: string) {
    const response = await api.get(`api/TwoFactor/enterBackupCode`, {
      params: {
        backupCode,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async sendMessageToUserForTwoFactor() {
    const response = await api.get(
      `api/TwoFactor/sendMessageToUserForTwoFactor`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async sendMessageForTwoFactor(data: ILoginIn) {
    const response = await api.post(`api/Account/sendMessageForTwoFactor`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async confirmCodeFromMessage(code: string) {
    const response = await api.put(
      `api/TwoFactor/confirmCodeFromMessage`,
      null,
      {
        params: {
          code,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // ADD Two Factor SMS
  async addPhoneNumberForTwoFactor(phoneNumber: string) {
    const response = await api.put(
      `api/TwoFactor/addPhoneNumberForTwoFactor`,
      null,
      {
        params: {
          phoneNumber,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getAuthenticatorAppInfo() {
    const response = await api.get(`api/TwoFactor/getAuthenticatorInfo`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // Remove Two Factor SMS
  async disableTwoFactorSMS() {
    const response = await api.get(`api/TwoFactor/disableTwoFactorSMS`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // Remove Two Factor Authenticator App
  async disableTwoFactorAuthenticatorApp() {
    const response = await api.get(
      `api/TwoFactor/disableTwoFactorAuthenticator`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // Wallet
  async getNonce() {
    const response = await apiOpt.get(`api/Account/getNonce`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async connectMetamaskWallet(hash: string, wallet: string) {
    const response = await apiOpt.post(
      `api/Account/connectMetamaskWallet`,
      null,
      {
        params: {
          hash,
          wallet,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Title || response.data.Description || '')
      )

    return response.data
  }

  async disconnectMetamaskWallet() {
    const response = await apiOpt.post(`api/Account/DropOpenSeaWallet`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Title || response.data.Description || '')
      )

    return response.data
  }

  async getOpenSeaWalletByUserId(userId: string) {
    try {
      const response = await apiOpt.get(
        `api/Account/GetOpenSeaWalletByUserId?userId=${userId}`
      )

      console.log('API response for OpenSeaWallet:', response)

      if (response.status === 204) {
        console.warn('No OpenSeaWallet found for user:', userId)
        return null
      }

      if (response.status !== 200) {
        console.error('Failed to fetch OpenSeaWallet, Status:', response.status)
        console.error('Response Data:', response.data)
        throw new Error(
          response.data &&
            (response.data.Description ||
              response.data.Title ||
              'Unknown error occurred while fetching OpenSeaWallet.')
        )
      }

      return response.data
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        console.error('Response data:', error.response.data)
        console.error('Response status:', error.response.status)
        console.error('Response headers:', error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request made, no response received:', error.request)
      } else {
        // Something else happened while setting up the request
        console.error('Error setting up request:', error.message)
      }
      throw error
    }
  }

  // Beta Wallet
  async getNonceBeta() {
    const response = await apiAlt.get(`api/Account/getNonce`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async connectMetamaskWalletBeta(hash: string, wallet: string) {
    const response = await apiAlt.post(
      `api/Account/connectMetamaskWallet`,
      null,
      {
        params: {
          hash,
          wallet,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Title || response.data.Description || '')
      )

    return response.data
  }

  async disconnectMetamaskWalletBeta() {
    const response = await apiAlt.post(`api/Account/DropOpenSeaWallet`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Title || response.data.Description || '')
      )

    return response.data
  }

  async getOpenSeaWalletBetaByUserId(userId: string) {
    try {
      const response = await apiAlt.get(
        `api/Account/GetOpenSeaWalletByUserId?userId=${userId}`
      )

      console.log('API response for OpenSeaWallet:', response)

      if (response.status === 204) {
        console.warn('No OpenSeaWallet found for user:', userId)
        return null
      }

      if (response.status !== 200) {
        console.error('Failed to fetch OpenSeaWallet, Status:', response.status)
        console.error('Response Data:', response.data)
        throw new Error(
          response.data &&
            (response.data.Description ||
              response.data.Title ||
              'Unknown error occurred while fetching OpenSeaWallet.')
        )
      }

      return response.data
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        console.error('Response data:', error.response.data)
        console.error('Response status:', error.response.status)
        console.error('Response headers:', error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request made, no response received:', error.request)
      } else {
        // Something else happened while setting up the request
        console.error('Error setting up request:', error.message)
      }
      throw error
    }
  }

  async getOpenSeaBetaWalletByUserId(userId: string) {
    try {
      const response = await apiAlt.get(
        `api/Account/GetOpenSeaWalletByUserId?userId=${userId}`
      )

      if (response.status === 204) {
        console.warn('No OpenSea wallet beta found for user ID:', userId)
        return null
      }

      return response.data
    } catch (error) {
      console.error('Error in getOpenSeaWalletBetaByUserId:', error)
      throw error
    }
  }

  // Password
  async getMarketplaceNonce() {
    try {
      const response = await apiAlt.get(`api/Account/getMarketplaceNonce`);

      if (response.status !== 200) {
        const errorMessage = response.data?.Description || response.data?.Title || 'An error occurred while getting the marketplace nonce.';
        throw new Error(errorMessage);
      }

      return response.data;
    } catch (error) {
      console.error('Error in getMarketplaceNonce:', error);
      throw error;
    }
  }

  async setPinMarketplaceMetamask(nonceHash: string, pin: string) {
    try {
      const response = await apiAlt.post(`api/Account/setPinMarketplaceMetamask?nonceHash=${nonceHash}&pin=${pin}`);

      if (response.status !== 200) {
        const errorMessage = response.data?.Description || response.data?.Title || 'An error occurred while setting the marketplace PIN.';
        throw new Error(errorMessage);
      }

      return response.data;
    } catch (error) {
      console.error('Error in setPinMarketplaceMetamask:', error);
      throw error;
    }
  }
}

export default new AccountStore()
